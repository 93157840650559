<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span
                                        class="text-capitalize">{{detailLoad.title}}
                                    </span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                        <div class="mt-3">
                            <div class="box border rounded p-4">
                                <div class="clearfix">
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.supplier')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.supplier ? detailLoad.supplier.name : ""}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.duration')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.duration}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.startDate')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{ $global.utcDateToLocalDate(detailLoad.start_date, 'DD.MM.YYYY') }}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.endDate')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{ $global.utcDateToLocalDate(detailLoad.end_date, 'DD.MM.YYYY') }}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row v-show="detailLoad.description">
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.description')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.description}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.attachments')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                <a v-if="detailLoad.contract_documents.length > 0"
                                                   v-b-modal.files-attachments
                                                   @click="getAttachmentItem(detailLoad.contract_documents)">
                                                    <i class="fe fe-paperclip"></i>Attachments</a>
                                                <a v-else href="javascript:;">No Attachment</a>
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <!--  attachment modal-->
        <b-modal id="files-attachments" ok-only :title="$t('title.attachments')" ok-title="Cancel">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{{$t('column.attachment')}}</th>
                        <th>{{$t('column.download')}}</th>
                        <th>{{$t('column.createdAt')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(file,index) in selectedAttachment">
                        <td>{{index+1}}</td>
                        <td>{{file.title}}.{{file.document.extension}}</td>
                        <td>
                            <a :title="$t('button.download')"
                               v-b-tooltip.hover variant="outline-primary"
                               class="mb-1"
                               v-if="file.document && file.document.download_url"
                               :disabled="!(file.document && file.document.download_url)"
                               :href="(file.document ? file.document.download_url : '')"
                               target="_blank">
                                <i class="fe fe-download"></i>
                            </a>
                        </td>
                        <td>
                            {{file.document.created_at ? $global.utcDateToLocalDate(file.document.created_at): ''}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {request} from "../../util/Request";

    export default {
        props: ['handleCloseOperation'],
        data() {
            return {
                detailLoad: null,
                selectedAttachment: [],
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                this.serverError()
            }
        },
        methods: {
            getAttachmentItem(item) {
                this.selectedAttachment = item
            },
            async getDetail(id) {
                await request({
                    url: `/contracts/detail/${id}`,
                })
                    .then((response) => {
                        const {data} = response
                        this.detailLoad = data
                    })
                    .catch((error) => {

                    })
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        }
    }
</script>
